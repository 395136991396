const ForeplayRouletteSettings = (props) => (
            <div className="card border-dark bg-dark m-3">
                <div className="card-header">
                    Settings
                </div>
                <div className="card-body container">
                    <div className="row">
                        <div className="col">
                            <h2 className="card-title">Body</h2>
                            <h3 className="card-title">Her</h3>
                            {props.her.map((part,index) => (
                                <div className="form-check form-switch" key={"her_"+index}>
                                    <input className="form-check-input" type="checkbox" id={"her_"+index} checked={part[1]} onChange={() => (props.updateLists([index, "her"]))} />
                                    <label className="form-check-label" for={"her_"+index}>{part[0]}</label>
                                </div>
                            ))}
                            <h3>Him</h3>
                            {props.him.map((part,index) => (
                                <div className="form-check form-switch" key={"him_"+index}>
                                    <input className="form-check-input" type="checkbox" id={"him_"+index} checked={part[1]} onChange={() => (props.updateLists([index, "him"]))} />
                                    <label className="form-check-label" for={"him_"+index}>{part[0]}</label>
                                </div>
                            ))}
                            <h3>Both</h3>
                            {props.both.map((part,index) => (
                                <div className="form-check form-switch" key={"both_"+index}>
                                    <input className="form-check-input" type="checkbox" id={"both_"+index} checked={part[1]} onChange={() => (props.updateLists([index, "both"]))} />
                                    <label className="form-check-label" for={"both_"+index}>{part[0]}</label>
                                </div>
                            ))}
                        </div>
                        <div className="col border-start border-secondary">
                            <h2 className="card-title">Actions</h2>
                            {props.actions.map((part,index) => (
                                <div className="form-check form-switch" key={"action_"+index}>
                                    <input className="form-check-input" type="checkbox" id={"action_"+index} checked={part[1]} onChange={() => (props.updateLists([index, "action"]))} />
                                    <label className="form-check-label" for={"action_"+index}>{part[0]}</label>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
)

export default ForeplayRouletteSettings