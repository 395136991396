import React from 'react'
import ForeplayRouletteSettings from './ForeplayRouletteSettings'

class ForeplayRoulette extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            turn: Math.round(Math.random()),
            players: ["Her", "His"],
            actions:[["Lick",true], ["Caress",true], ["Massage",true], ["Suck",true], ["Fondle",true], ["Kiss",true]],
            her: [["Breasts",true], ["Clitoris",true]],
            him: [["Penis",true]],
            both: [["Back",true], ["Hand",true], ["Lips",true], ["Ears",true], ["Butt",true], ["Thigh",true],["Neck",true], ["Choice",true], ["Knee",true]],
            bodySpinner: [],
            bodyPieSize: null,
            actionSpinner: [],
            actionPieSize: null,
            actionRotate: 0,
            bodyRotate: 0,
            settingsOpen: false
        }
    }

    componentDidMount = () => {
        this.setState({
            herCustom: [...this.state.her]
        })
        this.getAction()
    }

    updateReroll = (e) => this.setState({ reroll: e.target.value })

    updateLists = (e) => {
        const index = e[0]
        const list = e[1]
        let temp = []
        switch (list) {
            case "her":
                temp = this.state.her
                temp[index][1] = !this.state.her[index][1]
                this.setState({her: temp})
                break;
            case "him":
                temp = this.state.him
                temp[index][1] = !this.state.him[index][1]
                this.setState({him: temp})
                break;
            case "action":
                temp = this.state.actions
                temp[index][1] = !this.state.actions[index][1]
                this.setState({actions: temp})
                break;
            default:
                temp = this.state.both
                temp[index][1] = !this.state.both[index][1]
                this.setState({both: temp})
                break;
        }
    }

    randomInt = (min, max) => {
        min = Math.ceil(min)
        max = Math.floor(max)
        return Math.floor(Math.random() * (max - min) + min)
    }

    shuffleArray = array => {
        for (let i = array.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          const temp = array[i];
          array[i] = array[j];
          array[j] = temp;
        }
      }

    pruneList(array){
        let temp = []
        array.forEach(item => {
            if(item[1]){temp.push(item[0])}
        });
        return temp
    }

    getAction = (reroll) => {
        let bodySpinner = []
        let bodyPieSize = []
        const actionSpinner = this.pruneList([...this.state.actions])
        const actionPieSize = 360 / actionSpinner.length
        let nextTurn = (this.state.turn === 0 ? 1 : 0)

        //Checking Reroll
        if(reroll){
            nextTurn = this.state.turn
        }
        
        if (nextTurn === 0){
            bodySpinner = this.pruneList([...this.state.her, ...this.state.both])
            bodyPieSize = 360 / (bodySpinner).length
        }else{
            bodySpinner = this.pruneList([...this.state.him, ...this.state.both])
            bodyPieSize = 360 / (bodySpinner).length
        }
        
        //Shuffling the Spinners because they always land on spot 1
        this.shuffleArray(bodySpinner)
        this.shuffleArray(actionSpinner)
        
        //Getting a random spin different than the last
        let spinnerRotate = (360 * this.randomInt(5,50))
        while (spinnerRotate === this.state.spinnerRotate){
            spinnerRotate = (360 * this.randomInt(5,50))
        }

        this.setState({
            bodySpinner,
            bodyPieSize,
            actionSpinner,
            actionPieSize,
            spinnerRotate,
            turn: nextTurn
        })
    }

    openSettings = () => {
        this.setState({settingsOpen: !this.state.settingsOpen})
    }

    render() {
        return(
            <div id="gameWrapper">
                <div id="gameTitle">
                    <h1>Foreplay Roulette</h1>
                    <div onClick={this.openSettings} id="settingsBtn"><span className="material-icons-outlined">settings</span></div>
                </div>
                {(this.state.settingsOpen ?
                    <ForeplayRouletteSettings her={this.state.her} him={this.state.him} both={this.state.both} actions={this.state.actions} updateLists={this.updateLists} />
                :
                    <div id="fprWrapper">
                        <div id="spinnerOuter">
                            <div className="spinner_wrapper">
                                <div id="action_spinner" className="spinner" style={{transform: "rotate("+this.state.spinnerRotate+"deg)"}}>
                                    {
                                        this.state.actionSpinner.map((action, index) => (
                                            <div key={action+"_"+index} className="pie_item" style={{transform: "rotate("+this.state.actionPieSize * index+"deg)"}}>{action}</div>
                                        ))
                                    }
                                </div>
                            </div>
                            <div id="person">{this.state.players[this.state.turn]}</div>
                            <div className="spinner_wrapper">
                                <div id="body_spinner" className="spinner"  style={{transform: "rotate("+this.state.spinnerRotate+"deg)"}}>
                                    {
                                        this.state.bodySpinner.map((part, index) => (
                                            <div key={part+"_"+index} className="pie_item" style={{transform: "rotate("+this.state.bodyPieSize * index+"deg)"}}>{part}</div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                        <div id="btnWrapper">
                            <button id="btn_reroll" onClick={()=>this.getAction(true)}><span class="material-icons-outlined md-48">replay</span></button>
                            <button id="btn_continue" onClick={()=>this.getAction(false)}><span class="material-icons-outlined md-48">double_arrow</span></button>
                        </div>
                    </div>
                )}
            </div>
        )
    }
}

ForeplayRoulette.propTypes = {
}

ForeplayRoulette.defaultProps = {
}

export default ForeplayRoulette