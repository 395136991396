import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import ForeplayRoulette from './games/ForeplayRoulette';
import Home from "./home";
import './styles/main.css'

function App() {
  return (
    <Router>
      <header id="headerWrapper">
        <Link to="/" className="title"><span class="material-icons-outlined">home</span>Married Musings</Link>
      </header>
      <Switch>
        <Route path="/" exact>
          <Home />
        </Route>
        <Route path="/foreplay">
          <ForeplayRoulette />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
