import { Link } from "react-router-dom"

const Home = () => {
    return(
        <div id="homeList">
            <Link to="/foreplay" className="listItem"><span class="material-icons-outlined md-36 me-1 light-icon">attractions</span>Foreplay Roulette</Link>
        </div>
    )
}

export default Home